export const socialAuthCredential = {
    google: {
        clientId: "966964444945-ljtdgnmpedg69vmnk15jt7hvvkdnuoj0.apps.googleusercontent.com"
    },
    facebook: {
        // Buyer Dev
        //appId: "980946769343444"

        // Buyer UAT
        // appId: "686951389311493"
        appId: "806598453386254"
    },
    apple: {
        clientId: "ctbids.com",
        redirectURI: "https://ctbids.com"
    }
};
